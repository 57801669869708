export const dsaCourseModuleList = [
  {
    moduleNumber: 1,
    moduleName: "I. DSA Guidance",
    chapters: [
      {
        chapterNumber: 1,
        chapterName: "Why DSA Important?",
        path: "/blog/why-should-we-learn-data-structure-and-algorithms/",
      },
      {
        chapterNumber: 2,
        chapterName: "DSA Learning Challenges",
        path:
          "/blog/top-10-learning-challenges-in-data-structure-and-algorithms/",
      },
      {
        chapterNumber: 3,
        chapterName: "Develop Algorithmic Thinking",
        path:
          "/blog/how-to-develop-algorithmic-thinking-in-data-structure-and-algorithms/",
      },
      {
        chapterNumber: 4,
        chapterName: "DSA Study and Interview Guide",
        path:
          "/blog/step-by-step-guidance-to-master-data-structure-and-algorithms-for-coding-interview/",
      },
      {
        chapterNumber: 5,
        chapterName: "Coding Interview Learning Plan",
        path: "/blog/step-by-step-learning-plan-for-interview-success/",
      },
      {
        chapterNumber: 6,
        chapterName: "DSA Problem Solving Techniques",
        path:
          "/blog/problem-solving-approaches-in-data-structures-and-algorithms/",
      },
      {
        chapterNumber: 7,
        chapterName: "Competitive Coding Guide",
        path: "/blog/how-to-get-started-with-competitive-programming/",
      },
      {
        chapterNumber: 8,
        chapterName: "Steps of DSA Problem Solving",
        path:
          "/blog/steps-of-problem-solving-for-cracking-the-coding-interview/",
      },
      {
        chapterNumber: 9,
        chapterName: "Tips for Problem Solving",
        path: "/blog/tips-and-motivation-to-master-problem-solving/",
      },
    ],
  },
  {
    moduleNumber: 2,
    moduleName: "II. DSA Fundamentals",
    chapters: [
      {
        chapterNumber: 1,
        chapterName: "Introduction to Algorithms",
        path: "/blog/introduction-to-algorithms/",
      },
      {
        chapterNumber: 2,
        chapterName: "Introduction to Data Structures",
        path: "/blog/introduction-to-data-structures/",
      },
      {
        chapterNumber: 3,
        chapterName: "Time Complexity Analysis",
        path:
          "/blog/time-complexity-analysis-in-data-structure-and-algorithms/",
      },
      {
        chapterNumber: 4,
        chapterName: "What is Iteration?",
        path: "/blog/fundamentals-of-loop-in-programming-and-algorithms/",
      },
      {
        chapterNumber: 5,
        chapterName: "Analysis of Loop",
        path: "/blog/time-complexity-analysis-of-loop-in-programming/",
      },
      {
        chapterNumber: 6,
        chapterName: "How Recursion Works?",
        path: "/blog/recursion-explained-how-recursion-works-in-programming/",
      },
      {
        chapterNumber: 7,
        chapterName: "Analysis of Recursion",
        path: "/blog/time-complexity-analysis-of-recursion-in-programming/",
      },
      {
        chapterNumber: 8,
        chapterName: "Iteration vs. Recursion",
        path: "/blog/difference-between-iteration-and-recursion/",
      },
      {
        chapterNumber: 9,
        chapterName: "Divide and Conquer",
        path: "/blog/divide-and-conquer/",
      },
    ],
  },
  {
    moduleNumber: 3,
    moduleName: "III. Problem Solving using Loop",
    chapters: [
      {
        chapterNumber: 1,
        chapterName: "Buildings Facing Sun",
        path: "/blog/number-of-buildings-facing-sun/",
      },
      {
        chapterNumber: 2,
        chapterName: "Find Leaders in an Array",
        path: "/blog/leaders-in-an-array/",
      },
      {
        chapterNumber: 3,
        chapterName: "Valid Mountain Array",
        path: "/blog/valid-mountain-array/",
      },
      {
        chapterNumber: 4,
        chapterName: "Convert Roman to Integer",
        path: "/blog/roman-to-integer/",
      },
      {
        chapterNumber: 5,
        chapterName: "Find Equilibrium Index",
        path: "/blog/find-equilibrium-index-of-an-array/",
      },
      {
        chapterNumber: 6,
        chapterName: "Product of Array Except Self",
        path: "/blog/product-of-array-except-self/",
      },
      {
        chapterNumber: 7,
        chapterName: "Rotate Matrix by 90 Degrees",
        path:
          "/blog/rotate-a-matrix-by-90-degrees-in-an-anticlockwise-direction/",
      },
      {
        chapterNumber: 8,
        chapterName: "Print Matrix in Spiral Order",
        path: "/blog/print-matrix-in-spiral-order/",
      },
      {
        chapterNumber: 9,
        chapterName: "Find Min and Max in Array",
        path: "/blog/find-the-minimum-and-maximum-value-in-an-array/",
      },
    ],
  },
  {
    moduleNumber: 4,
    moduleName: "IV. Binary Search",
    chapters: [
      {
        chapterNumber: 1,
        chapterName: "Binary Search Algorithm",
        path: "/blog/binary-search-algorithm/",
      },

      {
        chapterNumber: 2,
        chapterName: "Fixed Point in Sorted Array",
        path: "/blog/find-a-fixed-point-in-a-given-array/",
      },
      {
        chapterNumber: 3,
        chapterName: "Max in Incr and Decr Array",
        path:
          "/blog/find-maximum-in-first-increasing-and-then-decreasing-array/",
      },
      {
        chapterNumber: 4,
        chapterName: "Find First and Last Positions",
        path: "/blog/first-and-last-positions-of-element-in-sorted-array/",
      },
      {
        chapterNumber: 5,
        chapterName: "Min in Sorted and Rotated Array",
        path: "/blog/minimum-in-sorted-and-rotated-array/",
      },
      {
        chapterNumber: 6,
        chapterName: "Search in a Sorted Matrix",
        path: "/blog/search-in-a-row-wise-sorted-2d-matrix/",
      },
      {
        chapterNumber: 7,
        chapterName: "Median of Sorted Arrays",
        path: "/blog/median-of-two-sorted-arrays/",
      },
      {
        chapterNumber: 8,
        chapterName: "Row with Max Ones",
        path: "/blog/find-row-with-maximum-number-of-1s/",
      },
    ],
  },
  {
    moduleNumber: 5,
    moduleName: "V. Sorting Algorithms",
    chapters: [
      {
        chapterNumber: 1,
        chapterName: "Why we learn Sorting?",
        path: "/blog/why-should-we-learn-sorting-algorithms/",
      },
      {
        chapterNumber: 2,
        chapterName: "Bubble, Selection, Insertion Sort",
        path:
          "/blog/introduction-to-sorting-bubble-sort-selection-sort-and-insertion-sort/",
      },
      {
        chapterNumber: 3,
        chapterName: "Merge Sort Algorithm",
        path: "/blog/merge-sort-algorithm/",
      },
      {
        chapterNumber: 4,
        chapterName: "Quick Sort Algorithm",
        path: "/blog/quick-sort-algorithm/",
      },
      {
        chapterNumber: 5,
        chapterName: "Lower Bound of Sorting",
        path: "/blog/lower-bound-of-comparison-sorting/",
      },
      {
        chapterNumber: 6,
        chapterName: "Counting Sort Algorithm",
        path: "/blog/counting-sort/",
      },
      {
        chapterNumber: 7,
        chapterName: "Sorting Algorithms Comparison",
        path: "/blog/comparison-of-sorting-algorithms/",
      },
      {
        chapterNumber: 8,
        chapterName: "Sort Array of 0s, 1s and 2s",
        path: "/blog/sort-an-array-of-0s-1s-and-2s/",
      },
      {
        chapterNumber: 9,
        chapterName: "Sort Array in Waveform",
        path: "/blog/sort-an-array-in-a-waveform/",
      },
    ],
  },
  {
    moduleNumber: 6,
    moduleName: "VI. Two Pointers, Sliding Window",
    chapters: [
      {
        chapterNumber: 1,
        chapterName: "Move Zeroes to End",
        path: "/blog/move-all-the-zeroes-to-the-end/",
      },
      {
        chapterNumber: 2,
        chapterName: "Remove Duplicates from Array",
        path: "/blog/remove-duplicates-from-sorted-array/",
      },
      {
        chapterNumber: 3,
        chapterName: "Container with Most Water",
        path: "/blog/container-with-most-water/",
      },
      {
        chapterNumber: 4,
        chapterName: "Trapping Rainwater",
        path: "/blog/trapping-rain-water/",
      },
      {
        chapterNumber: 5,
        chapterName: "Longest Substring of Characters",
        path: "/blog/longest-substring-without-repeating-characters/",
      },
      {
        chapterNumber: 6,
        chapterName: "Distinct Elements in Window",
        path: "/blog/count-distinct-elements-in-every-window/",
      },
      {
        chapterNumber: 7,
        chapterName: "Max Continuous Series of 1s",
        path: "/blog/max-consecutive-ones/",
      },
      {
        chapterNumber: 8,
        chapterName: "Max Consecutive 1's",
        path: "/blog/max-consecutive-ones-II/",
      },
      {
        chapterNumber: 9,
        chapterName: "N Repeated Element in Array",
        path: "/blog/n-repeated-element-in-2n-size-array/",
      },
    ],
  },
  {
    moduleNumber: 7,
    moduleName: "VII. Hashing and Hash Table",
    chapters: [
      {
        chapterNumber: 1,
        chapterName: "Introduction to Hashing",
        path: "/blog/introduction-to-hashing-and-hash-table/",
      },
      {
        chapterNumber: 2,
        chapterName: "Hash Functions in Data Structure",
        path: "/blog/introduction-to-hash-functions/",
      },
      {
        chapterNumber: 3,
        chapterName: "Check Arrays are Equal or Not",
        path: "/blog/check-equal-arrays/",
      },
      {
        chapterNumber: 4,
        chapterName: "Most Frequent Element in Array",
        path: "/blog/most-frequent-element-in-array/",
      },
      {
        chapterNumber: 5,
        chapterName: "Largest Subarray with 0 Sum",
        path: "/blog/largest-subarray-with-zero-sum/",
      },
      {
        chapterNumber: 6,
        chapterName: "Longest Consecutive Sequence",
        path: "/blog/longest-consecutive-sequence/",
      },
      {
        chapterNumber: 7,
        chapterName: "Check Anagram Strings",
        path: "/blog/valid-anagram/",
      },
      {
        chapterNumber: 8,
        chapterName: "Sort Characters by Frequency",
        path: "/blog/sort-characters-by-frequency/",
      },
      {
        chapterNumber: 9,
        chapterName: "First Missing Positive",
        path: "/blog/first-missing-positive/",
      },
    ],
  },
  {
    moduleNumber: 8,
    moduleName: "VIII. Additional Practice Problems",
    chapters: [
      {
        chapterNumber: 1,
        chapterName: "Fizz Buzz Problem",
        path: "/blog/fizz-buzz-problem/",
      },
      {
        chapterNumber: 2,
        chapterName: "Check Pair Sum in Array",
        path: "/blog/check-for-pair-in-an-array-with-a-given-sum/",
      },
      {
        chapterNumber: 3,
        chapterName: "Triplets with Zero Sum",
        path: "/blog/triplet-with-zero-sum/",
      },
      {
        chapterNumber: 4,
        chapterName: "Intersection of Two Arrays",
        path: "/blog/intersection-of-unsorted-arrays/",
      },
      {
        chapterNumber: 5,
        chapterName: "Check Arrays are Subset or Not",
        path: "/blog/check-whether-array-is-subset-of-another-array/",
      },
      {
        chapterNumber: 6,
        chapterName: "Maximum Difference in Array",
        path: "/blog/maximum-difference-between-two-elements/",
      },
      {
        chapterNumber: 7,
        chapterName: "Maximum Index Difference",
        path: "/blog/find-the-maximum-j-i-in-array/",
      },
      {
        chapterNumber: 8,
        chapterName: "Majority Element in Array",
        path: "/blog/find-the-majority-element-in-an-array/",
      },
      {
        chapterNumber: 9,
        chapterName: "Count Valid Triangle Number",
        path: "/blog/count-the-number-of-possible-triangles/",
      },
    ],
  },
  {
    moduleNumber: 9,
    moduleName: "IX. Array and Linked List",
    chapters: [
      {
        chapterNumber: 1,
        chapterName: "Array in Data Structure",
        path: "/blog/introduction-to-arrays/",
      },
      {
        chapterNumber: 2,
        chapterName: "Dynamic Array Data Structure",
        path: "/blog/dynamic-array/",
      },
      {
        chapterNumber: 3,
        chapterName: "Middle Element in Linked List",
        path: "/blog/find-middle-of-linked-list/",
      },
      {
        chapterNumber: 4,
        chapterName: "Reverse Linked List",
        path: "/blog/reverse-linked-list/",
      },
      {
        chapterNumber: 5,
        chapterName: "Remove Nth Node from List End",
        path: "/blog/remove-nth-node-from-list-end/",
      },
      {
        chapterNumber: 6,
        chapterName: "Intersection of Sorted Linked Lists",
        path: "/blog/intersection-of-sorted-linked-lists/",
      },
      {
        chapterNumber: 7,
        chapterName: "Swap List Nodes in Pairs",
        path: "/blog/swap-list-nodes-in-pairs/",
      },
      {
        chapterNumber: 8,
        chapterName: "Detect Loop in Linked List",
        path: "/blog/detect-loop-in-linked-list/",
      },
      {
        chapterNumber: 9,
        chapterName: "Insertion Sort Linked List",
        path: "/blog/sort-linked-list-using-insertion-sort/",
      },
    ],
  },
  {
    moduleNumber: 10,
    moduleName: "X. Stack and Queue",
    chapters: [
      {
        chapterNumber: 1,
        chapterName: "Introduction to Stack",
        path: "/blog/stack-data-structure/",
      },
      {
        chapterNumber: 2,
        chapterName: "Application of Stack",
        path: "/blog/application-of-stack-data-structure-in-programming/",
      },
      {
        chapterNumber: 3,
        chapterName: "Sort Stack using Stack",
        path: "/blog/sort-stack-using-temporary-stack/",
      },
      {
        chapterNumber: 4,
        chapterName: "Next Greater Element",
        path: "/blog/find-next-greater-element/",
      },
      {
        chapterNumber: 5,
        chapterName: "Implement Queue using Stacks",
        path: "/blog/queue-using-stacks/",
      },
      {
        chapterNumber: 6,
        chapterName: "Implement Stack using Queues",
        path: "/blog/stack-using-queues/",
      },
      {
        chapterNumber: 7,
        chapterName: "Valid Parentheses Expression",
        path: "/blog/check-for-balanced-parentheses-in-expression/",
      },
      {
        chapterNumber: 8,
        chapterName: "Min Stack Problem",
        path: "/blog/min-stack-problem/",
      },
    ],
  },
  {
    moduleNumber: 11,
    moduleName: "XI. Binary Tree",
    chapters: [
      {
        chapterNumber: 1,
        chapterName: "Introduction to Binary Tree",
        path: "/blog/introduction-to-binary-tree/",
      },
      {
        chapterNumber: 2,
        chapterName: "Recursive Binary Tree Traversal",
        path: "/blog/binary-tree-traversals-preorder-inorder-postorder/",
      },
      {
        chapterNumber: 3,
        chapterName: "Iterative Binary Tree Traversal",
        path: "/blog/iterative-binary-tree-traversals-using-stack/",
      },
      {
        chapterNumber: 4,
        chapterName: "Level Order Traversal",
        path: "/blog/level-order-traversal-of-binary-tree/",
      },
      {
        chapterNumber: 5,
        chapterName: "Height of a Binary Tree",
        path: "/blog/find-height-of-a-binary-tree/",
      },
      {
        chapterNumber: 6,
        chapterName: "Min Depth of a Binary Tree",
        path: "/blog/min-depth-of-binary-tree/",
      },
      {
        chapterNumber: 7,
        chapterName: "Merge Two Binary Trees",
        path: "/blog/merge-binary-tree/",
      },
      {
        chapterNumber: 8,
        chapterName: "Left View of Binary Tree",
        path: "/blog/left-view-of-binary-tree/",
      },
    ],
  },
  {
    moduleNumber: 12,
    moduleName: "XII. BST and Heap",
    chapters: [
      {
        chapterNumber: 1,
        chapterName: "Introduction to BST",
        path: "/blog/introduction-to-binary-search-tree/",
      },
      {
        chapterNumber: 2,
        chapterName: "Searching in BST",
        path: "/blog/searching-in-binary-search-tree/",
      },
      {
        chapterNumber: 3,
        chapterName: "Insertion in BST",
        path: "/blog/insertion-in-binary-search-tree/",
      },
      {
        chapterNumber: 4,
        chapterName: "Deletion in BST",
        path: "/blog/deletion-in-binary-search-tree/",
      },
      {
        chapterNumber: 5,
        chapterName: "Inorder Successor in BST",
        path: "/blog/inorder-successor-in-binary-search-tree/",
      },
      {
        chapterNumber: 6,
        chapterName: "Inorder Predecessor in BST",
        path: "/blog/inorder-predecessor-binary-search-tree/",
      },
      {
        chapterNumber: 7,
        chapterName: "What is Heap Data Structure?",
        path: "/blog/introduction-to-heap-data-structure/",
      },
      {
        chapterNumber: 8,
        chapterName: "Kth Smallest Element in Array",
        path: "/blog/find-the-kth-smallest-element-in-an-array/",
      },
    ],
  },
  {
    moduleNumber: 13,
    moduleName: "XIII. Advanced Data Structures",
    chapters: [
      {
        chapterNumber: 1,
        chapterName: "Introduction to AVL Tree",
        path: "/blog/avl-tree-data-structure/",
      },
      {
        chapterNumber: 2,
        chapterName: "Introduction to Trie",
        path: "/blog/introduction-to-trie-data-structure/",
      },
      {
        chapterNumber: 3,
        chapterName: "Binary Indexed Tree",
        path: "/blog/binary-indexed-tree/",
      },
      {
        chapterNumber: 4,
        chapterName: "Segment Tree Part 1",
        path: "/blog/segment-tree-data-structure-part1/",
      },
      {
        chapterNumber: 5,
        chapterName: "Segment Tree Part 2",
        path: "/blog/segment-trees-data-structure-part-2/",
      },
      {
        chapterNumber: 6,
        chapterName: "Implement LRU Cache",
        path: "/blog/implement-least-recently-used-cache/",
      },
      {
        chapterNumber: 7,
        chapterName: "Implement LFU Cache",
        path: "/blog/least-frequently-used-cache/",
      },
      {
        chapterNumber: 8,
        chapterName: "Implement Bloom Filter",
        path: "/blog/bloom-filter/",
      },
      {
        chapterNumber: 9,
        chapterName: "Introduction to N-ary Tree",
        path: "/blog/n-ary-tree/",
      },
      {
        chapterNumber: 10,
        chapterName: "Introduction to Suffix Tree",
        path: "/blog/suffix-tree-data-structure/",
      },
    ],
  },
  {
    moduleNumber: 14,
    moduleName: "XIV. Dynamic Programming",
    chapters: [
      {
        chapterNumber: 1,
        chapterName: "Introduction to DP",
        path: "/blog/introduction-to-dynamic-programming/",
      },
      {
        chapterNumber: 2,
        chapterName: "Top-down vs Bottom-up",
        path: "/blog/top-down-memoization-vs-bottom-up-tabulation/",
      },
      {
        chapterNumber: 3,
        chapterName: "DP vs Divide and Conquer",
        path: "/blog/dynamic-programming-vs-divide-and-conquer-approach/",
      },
      {
        chapterNumber: 4,
        chapterName: "Types of DP Problems",
        path: "/blog/what-common-problems-solved-using-dynamic-programming/",
      },
      {
        chapterNumber: 5,
        chapterName: "Climbing Stairs Problem",
        path: "/blog/climbing-stairs-problem/",
      },
      {
        chapterNumber: 6,
        chapterName: "Minimum Coin Change",
        path: "/blog/minimum-coin-change/",
      },
      {
        chapterNumber: 7,
        chapterName: "Maximum Subarray Sum",
        path: "/blog/maximum-subarray-sum/",
      },
      {
        chapterNumber: 8,
        chapterName: "Longest Common Subsequence",
        path: "/blog/longest-common-subsequence/",
      },
      {
        chapterNumber: 9,
        chapterName: "Minimum Number of Jumps",
        path: "/blog/minimum-number-of-jumps-to-reach-end/",
      },
      {
        chapterNumber: 10,
        chapterName: "0-1 Knapsack Problem",
        path: "/blog/zero-one-knapsack-problem/",
      },
    ],
  },
  {
    moduleNumber: 15,
    moduleName: "XV. Additional Practice Problems",
    chapters: [
      {
        chapterNumber: 1,
        chapterName: "Edit Distance Algorithm",
        path: "/blog/edit-distance/",
      },
      {
        chapterNumber: 2,
        chapterName: "Count Unique BSTs",
        path: "/blog/total-number-of-binary-search-trees-with-n-keys/",
      },
      {
        chapterNumber: 3,
        chapterName: "Sorted Array to Balanced BST",
        path: "/blog/sorted-array-to-balanced-bst/",
      },
      {
        chapterNumber: 4,
        chapterName: "Validate binary search tree",
        path: "/blog/validate-binary-search-tree/",
      },
      {
        chapterNumber: 5,
        chapterName: "Min Absolute Difference in BST",
        path: "/blog/minimum-absolute-difference-in-bst/",
      },
      {
        chapterNumber: 6,
        chapterName: "Kth Largest Element in BST",
        path: "/blog/kth-largest-element-in-bst/",
      },

      {
        chapterNumber: 7,
        chapterName: "Merge Overlapping Intervals",
        path: "/blog/merge-overlapping-intervals/",
      },
      {
        chapterNumber: 8,
        chapterName: "Count Inversions of an Array",
        path: "/blog/inversion-count-in-array/",
      },
      {
        chapterNumber: 9,
        chapterName: "Flood Fill Algorithm",
        path: "/blog/flood-fill-problem/",
      },
      {
        chapterNumber: 10,
        chapterName: "Generate K-combinations",
        path: "/blog/find-all-combinations-of-k-numbers/",
      },
    ],
  },
]
